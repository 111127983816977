export default {
    mounted: (el, { value }) => {
        const tooltip = document.createElement('div');
        tooltip.classList.add('tooltip__tip');
        tooltip.innerText = value;

        el.appendChild(tooltip);
        el.classList.add('tooltip');
    },
};
