import VanillaTilt from 'vanilla-tilt';

export default {
    mounted: el => {
        VanillaTilt.init(el, {
            reverse: true,
            max: 3,
        });
    },
    beforeUnmount: el => {
        el.vanillaTilt.destroy();
    },
};
