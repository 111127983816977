<template>
    <button type="button" @click.prevent="share">
        <slot />
    </button>
</template>

<script>
    export default {
        props: {
            title: {
                type: String,
                required: true,
            },

            text: {
                type: String,
                required: true,
            },

            url: {
                type: String,
                required: true,
            },
        },

        setup(props) {
            const share = async() => {
                const shareData = {
                    ...props,
                };

                try {
                    await navigator.share(shareData);
                } catch (error) {
                    console.error(error);
                }
            };

            return {
                share,
            };
        },
    };
</script>
