// CSS needs to be imported here as it's treated as a module
import '@/css/style.pcss';

// ================================================
// All third-party, globally-required libraries should be loaded here
// ================================================

// Support for lazyload images (and bg images)
import 'lazysizes';
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks';
import 'lazysizes/plugins/bgset/ls.bgset';
import 'lazysizes/plugins/attrchange/ls.attrchange';

// ================================================
// Setup Vue3
// ================================================
import {
    createApp,
    onMounted,
    onBeforeUnmount,
    ref,
} from 'vue';

// Swiper
import { Swiper, SwiperSlide } from 'swiper/vue';
import {
    Pagination,
    Navigation,
    Autoplay,
    Thumbs,
} from 'swiper/modules';

// Collapse transition
import CollapseTransition from '@ivanv/vue-collapse-transition/src/CollapseTransition.vue';

// Components
import Accordion from './components/Accordion.vue';
import Design from './components/Design.vue';
import Designs from './components/Designs.vue';
import FormSelect from './components/FormSelect.vue';
import Lightbox from './components/Lightbox.vue';
import ShareButton from './components/ShareButton.vue';

// Directives
import vClickOutside from 'click-outside-vue3';
import tilt from './directives/tilt';
import tooltip from './directives/tooltip';

const app = createApp({
    delimiters: [ '${', '}' ],

    setup() {
        const swiperModules = ref([ Pagination, Navigation, Autoplay, Thumbs ]);

        const isMenuOpen = ref(false);
        const isScrolled = ref(window.scrollY > 0);
        const activeNode = ref(null);

        function onToggleMenu() {
            isMenuOpen.value = !isMenuOpen.value;
        }

        function activateNode(nodeId) {
            if (activeNode.value === nodeId) {
                activeNode.value = null;
                return;
            }

            activeNode.value = nodeId;
        }

        function handleScroll() {
            isScrolled.value = window.scrollY > 0;
        }

        onMounted(() => {
            window.addEventListener('scroll', handleScroll);
        });

        onBeforeUnmount(() => {
            window.removeEventListener('scroll', handleScroll);
        });

        return {
            swiperModules,

            isMenuOpen,
            isScrolled,
            onToggleMenu,

            activeNode,
            activateNode,
        };
    },
});

app.component('Accordion', Accordion);
app.component('CollapseTransition', CollapseTransition);
app.component('Design', Design);
app.component('Designs', Designs);
app.component('FormSelect', FormSelect);
app.component('Lightbox', Lightbox);
app.component('ShareButton', ShareButton);
app.component('Swiper', Swiper);
app.component('SwiperSlide', SwiperSlide);

app.use(vClickOutside);

app.directive('tooltip', tooltip);
app.directive('tilt', tilt);

app.mount('#app');
