<template>
    <button type="button" class="relative" @click.prevent="onPlayVideo">
        <slot />

        <span class="block absolute align-x-y right-auto bottom-auto w-auto h-auto text-black pulse">
            <span class="block relative rounded-full bg-white z-1">
                <play-icon class="block w-16 h-16 -mx-px" />
            </span>
        </span>
    </button>
</template>

<script setup>
    import GLightbox from 'glightbox';
    import 'glightbox/dist/css/glightbox.css';

    import PlayIcon from '../../img/icons/circle-play-solid.svg';

    const props = defineProps({
        url: {
            type: String,
            required: true,
        },
        provider: {
            type: String,
            default: 'local',
        },
    });

    const onPlayVideo = () => {
        const gallery = GLightbox({
            elements: [
                {
                    href: props.url,
                    type: 'video',
                    source: props.provider.toLowerCase(),
                    width: 900,
                },
            ],
            autoplayVideos: true,
        });

        gallery.open();
    };
</script>
